import React from "react";
import DashboardSideNav from "./DashboardSideNav";

const DashboardComponent= ()=>{
    return(
        <div>
            <DashboardSideNav/>
        </div>
    )
};

export default DashboardComponent;