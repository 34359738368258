import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./index.css";

import Main from "./components/MainComponent";

function App() {
	return (
		<div className="App">
			<Main />
		</div>
	);
}

export default App;
